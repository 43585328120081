/*! auth.js | Bulkit | CSS Ninja */

/* ==========================================================================
Authentication and registration pages JS file
========================================================================== */

$(document).ready(function ($) {

    "use strict";

    initAuth();

})